<template>
  <div>
    <section class="section-watch grid-watch" id="certificate" style="background: #232323;">
      <!-- LEFT -->
      <div class="watch-content watch-left">
        <div class="jbtn-watch-link">
          <!-- {{ $C.MODELS_INDEX[watch.model] }} -->
          <img id="watch-model-logo" :src="model.assets.logo" alt="" />
        </div>
        <!-- <img id="watch-model-img" src="@/assets/models/first-edition/armband/front.png" alt="" /> -->
        <img id="watch-model-img" :src="model.assets.front" alt="" />
        <div class="jbtn jbtn-green jbtn-status">{{ $C.STATUS.WATCH_STRINGS[watch.status]  }}</div>
        <div @click="generatePdf()" class="jbtn jbtn-white">
          <span v-if="isGeneratingPdf" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
          <span v-else key="button">Certificate</span>
        </div>

        <div v-if="watch.serial" class="mywatch-content jbtn-watch-link watch-right-serial">
          <small>SERIAL</small><br />
          {{ watch.serial }}
        </div>
      </div>

      <!-- RIGHT -->
      <div class="watch-content watch-right">
        <div class="grid-2 duke-style">
          <router-link :to="{ name: 'Certificate', params: { id: watchId } }" class="jcard-duke">
            <i class="fal fa-watch fa-3x mb-3"></i>
            <h5>Watch Info</h5>
            <!-- <p class="m-0"><small>MY WATCH</small></p> -->
          </router-link>
          <router-link :to="{ name: 'ServicesList', params: { watchId: watchId } }" class="jcard-duke">
            <i class="fal fa-cogs fa-3x mb-3"></i>
            <h5>Servicing</h5>
          </router-link>
          <router-link :to="{ name: 'ServiceHistory', params: { id: watchId } }" class="jcard-duke">
            <i class="fal fa-calendar-alt fa-3x mb-3"></i>
            <h5>Service History</h5>
          </router-link>
          <!-- <router-link :to="{ name: 'Tutorials', params: { id: watchId } }" class="jcard-duke">
            <i class="fal fa-video fa-3x mb-3"></i>
            <h5>Tutorials</h5>
          </router-link>
          <router-link :to="{ name: 'PaymentsWatch' }" class="jcard-duke">
            <i class="fal fa-credit-card fa-3x mb-3"></i>
            <h5>Payments</h5>
          </router-link> -->
          <router-link :to="{ name: 'Warranty', params: { id: watchId } }" class="jcard-duke">
            <i class="fal fa-file-alt fa-3x mb-3"></i>
            <h5>Warranty</h5>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import toast from "@/assets/js/toast";
import axios from "axios";
// import { saveAs } from "file-saver";
import moment from "moment";
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "MyWatch",
  data() {
    return {
      isLoading: false,
      isGeneratingPdf: false,
      userId: "",
      watchId: "",
      modelId: "",

      model: {
        id: "",
        name: "",
        assets: {
          front: ""
        }
      },
      //lists
      appointmentsList: [],
      servicesMap: new Map(),

      listenerRegistrations: [],
    };
  },
  methods: {
    getWatch: function(watchId) {
      // LOAD watch
      // TODO: use snapshotlistener & implement architecture for proper listenere de-&-registration?
      var watchRef = db.collection(C.COLLECTION.WATCHES).doc(watchId);
      watchRef.get().then(doc => {
        //add id to doc
        const docdata = doc.data();
        docdata.id = doc.id;
        //set watch doc in store
        this.$store.commit("setWatch", docdata);
        //   this.userId = doc.data().userId;

        //load watch model
        this.modelId = doc.data().modelId;
        this.getWatchModel();
      });
    },
    // LOAD watch model
    getWatchModel: function() {
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .doc(this.modelId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.model = docdata;
        })
        .catch(error => {
          console.log("Error getting WATCH MODEL document: ", error);
        });
    },

    // LOAD services completed
    allAppointments: function() {
      this.isLoading = true;
      var appointmentsRef = db
        .collection(C.COLLECTION.APPOINTMENTS)
        .where("userId", "==", auth.currentUser.uid)
        .where("paid", "==", true);
        appointmentsRef = appointmentsRef.where("watchId", "==", this.watchId);
      appointmentsRef = appointmentsRef.where("status", "==", C.STATUS.APPOINTMENT_STATUS.COMPLETED);

      const registration = appointmentsRef.orderBy("createdOn", "desc").onSnapshot(querySnapshot => {
        this.appointmentsList.splice(0);
        querySnapshot.forEach(appointmentDoc => {
          const appointment = appointmentDoc.data();
          appointment.id = appointmentDoc.id;
          this.appointmentsList.push(appointment);
        });
        this.isLoading = false;
      });
      this.listenerRegistrations.push(registration);
    },

    // GENERATE CERTIFICATE PDF
    generatePdf: function() {
      if (this.isGeneratingPdf) return;

      this.isGeneratingPdf = true;
      const pdfData = {
        request: "generate_certificate",
        responseType: "json",

        first_name: this.user.firstName,
        last_name: this.user.lastName,
        model_name: this.model.name,
        model_link: C.getModelLink(this.model.name), //TODO
        serial: this.watch.serial,
        // address: this.user.addr,
        // zip: this.user.zip, 
        // city: this.user.city,
        // country: this.user.country,
        purchsed_on: this.watch.purchasedOn,
        warranty_expiry: moment(C.getDateFromTimestamp(this.watch.warranties[this.watch.warrantyActive].end)).format("MMM YYYY"), //TO TEST
        purchsed_at: this.watch.purchasedAt,
        limited_edition: this.model.edition,
        warranty_1_start: moment(C.getDateFromTimestamp(this.watch.warranties[0].start)).format("MMM YYYY"),
        warranty_1_end: moment(C.getDateFromTimestamp(this.watch.warranties[0].end)).format("MMM YYYY"),
        warranty_1_status: C.STATUS.WARRANTY_STATUS_INDEX[this.watch.warranties[0].status],
        warranty_2_start: moment(C.getDateFromTimestamp(this.watch.warranties[1].start)).format("MMM YYYY"),
        warranty_2_end: moment(C.getDateFromTimestamp(this.watch.warranties[1].end)).format("MMM YYYY"),
        warranty_2_status: C.STATUS.WARRANTY_STATUS_INDEX[this.watch.warranties[1].status],
        warranty_3_start: moment(C.getDateFromTimestamp(this.watch.warranties[2].start)).format("MMM YYYY"),
        warranty_3_end: moment(C.getDateFromTimestamp(this.watch.warranties[2].end)).format("MMM YYYY"),
        warranty_3_status: C.STATUS.WARRANTY_STATUS_INDEX[this.watch.warranties[2].status],
        services: this.appointmentsList
      };
      // console.log(pdfData);

      axios
        .post("/api/functions.php", pdfData)
        .then((response)=> {
          if (response.data != "") {
            // console.log("PDF READY: /api/" + response.data);
            // saveAs(response.data, 'duke_certificate.pdf');

            const url = "/api/" + response.data;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "duke_certificate.pdf");
            document.body.appendChild(link);
            link.click();

          } else {
            console.log("axios generate pdf: Empty response.");
          }
          this.isGeneratingPdf = false;
        })
        .catch((error) => {
                    console.log(error);
          this.isGeneratingPdf = false;
        });
    }
  },

  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    watch: {
      get() {
        return this.$store.getters.getWatch;
      }
    }
  },
  mounted() {
    //get watch id from params
    if (this.$route.params.watchId != null && this.$route.params.watchId != "") {
      this.watchId = this.$route.params.watchId;
    }
    // check if watch needs to be loaded
    if (!this.watch || this.watch.id != this.watchId) {
      this.getWatch(this.watchId);
    } else {
      this.modelId = this.watch.modelId;
      this.getWatchModel();
    }

    // load appointments
    this.allAppointments();

    // check if user needs to be loaded
    this.userId = auth.currentUser.uid;
    // if (this.user || this.user.id != this.userId) {
    //   return;
    // }
    //load user
    var userDocRef = db.collection(C.COLLECTION.USERS).doc(this.userId);
    userDocRef
      .get()
      .then(userDoc => {
        this.$store.commit("setUser", userDoc.data());
      })
      .catch(function(error) {
        console.log("Error getting USER document:", error);
      });
  },
  beforeDestroy() {    
    if (this.listenerRegistrations.length > 0) {
      this.listenerRegistrations.forEach(registration => {
        registration();
      });
    }
  }
};
</script>

<style scoped></style>
